import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Form, Input, Spin, Image, Typography } from "antd";
import { AiFillLock } from "react-icons/ai";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/auth";
import SelectLaw from "../components/SelectLaw";
import Select from "../components/Select";
import { mainApi } from "../utils/api";
import { successAlert, errorAlert } from "../utils/alert";
import Swal from "sweetalert2";
import FileUpload from "../components/Upload";
import moment from "moment";
import { DATE_FORMAT, TYPE } from "../const";
const { Paragraph } = Typography;
const { TextArea } = Input;

const Forms = () => {
  const { id } = useParams();
  const { user } = useAuth();

  const history = useNavigate();
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState(null);
  const [edit, setedit] = useState(false);
  // const [reasonType, setreasonType] = useState(data?.reasonType);
  // const [law, setlaw] = useState(data?.law);

  useEffect(() => {
    id && fetch();
  }, []);
  const fetch = async () => {
    try {
      setloading(true);
      let res = await mainApi({
        url: `/case/${id}`,
        method: "GET",
      });

      setdata(res.data);
      console.log("🚀 ~ file: Form.js ~ line 41 ~ fetch ~ res.data", res.data);
      // setlaw(res.data.law);
      // console.log("🚀 ~ file: Form.js ~ line 41 ~ fetch ~ res.data", res.data);

      setloading(false);
    } catch (err) {
      setloading(false);
    }
  };
  const onFinish = async (data) => {
    console.log("🚀 ~ file: Form.js ~ line 44 ~ onFinish ~ data", data);
    try {
      setloading(true);
      const res = await mainApi({
        method: id ? "PUT" : "POST",
        url: id ? `/case/${id}` : "/case",
        data: data,
      });
      if (res.status == 200) {
        history(-1);
        successAlert("", "Амжилттай хадгалагдлаа");
      }

      setloading(false);
    } catch (err) {
      setloading(false);
    }

    // onFinish;
  };
  const onVerify = async (data) => {
    console.log("🚀 ~ file: Form.js ~ line 71 ~ onVerify ~ data", data);
    if (data?.reason_type === 1 && !data?.law)
      return errorAlert("Зөрчлийн хууль сонгоно уу.");
    try {
      setloading(true);
      const res = await mainApi({
        method: "PUT",
        url: `/case/verify/${id}`,
        data: {
          law: data.law,
          reason_type: data.reason_type,
        },
      });
      if (res.status == 200) {
        history(-1);
        successAlert("", "Амжилттай хадгалагдлаа");
      }

      setloading(false);
    } catch (err) {
      setloading(false);
    }

    // onFinish;
  };

  return (
    <div className="flex flex-col  p-2">
      <div className="flex justify-between items-center p-3 border-l-8 border-def-primary mx-2">
        <div className="text-sm font-semibold">Зөрчилийн мэдээлэл</div>
      </div>
      <div className="flex w-full justify-between p-2 my-4">
        <Button
          primary={true}
          icon={<ArrowLeftOutlined />}
          size="middle"
          onClick={() => history(-1)}
        />
      </div>
      {loading ? (
        <div className="flex w-full h-32 justify-center items-center align-middle">
          <Spin size="large" />
        </div>
      ) : (
        <div className="p-4 flex flex-wrap bg-white">
          <div className="w-1/2 p-2">
            <Form className="bg-white " layout="vertical">
              <Form.Item
                label="Зураг"
                className="justify-center"
                // rules={[{ required: true }]}
              >
                {data?.from == "ORGIL" ? (
                  <div>
                    <img
                      height={300}
                      width="90%"
                      src={`https://polite-api.sys.mn/remote-image?url=${data?.photo_1_url}`}
                      alt=" "
                    ></img>

                    <img
                      className="mt-5"
                      height={300}
                      width="90%"
                      src={`https://polite-api.sys.mn/remote-image?url=${data?.photo_2_url}`}
                      alt=" "
                    ></img>
                  </div>
                ) : (
                  <div>
                    <img
                      height={300}
                      width="90%"
                      crossOrigin="anonymous"
                      src={data?.photo_1_url}
                      alt=" "
                    ></img>

                    <img
                      className="mt-5"
                      height={300}
                      width="90%"
                      crossOrigin="anonymous"
                      src={data?.photo_2_url}
                      alt=" "
                    ></img>
                  </div>
                )}
              </Form.Item>
              <div className="flex flex-row">
                <div className="w-1/2 p-2">
                  <Form.Item label="Зөрчил">
                    <div className="-mt-5 font-bold">{data?.violation}</div>
                  </Form.Item>
                </div>
                <div className="w-1/2 p-2">
                  <Form.Item label="Зөрчил огноо">
                    <div className="-mt-5 font-bold">
                      {moment(data?.case_date).format(DATE_FORMAT)}
                    </div>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
          <div className="w-1/2 p-2 ">
            <div>
              <Form className="bg-white " layout="vertical">
                <Typography.Title
                  className="border-l-4 border-def-primary pl-2"
                  level={5}
                  style={{ margin: 0 }}
                >
                  Эзэмшигчийн мэдээлэл
                </Typography.Title>
                <div className="flex flex-row">
                  <div className="w-1/2 p-2">
                    <Form.Item label="Эцэг/эх/-ийн нэр">
                      <div className="-mt-5 font-bold">
                        {data?.owner_lastname
                          ? data?.owner_lastname
                          : "Байхгүй"}
                      </div>
                    </Form.Item>
                    <Form.Item label="Регистрийн №">
                      <div className="-mt-5 font-bold">
                        {data?.owner_register
                          ? data?.owner_register
                          : "Байхгүй"}
                      </div>
                    </Form.Item>
                    <Form.Item label="Иргэншил">
                      <div className="-mt-5 font-bold">
                        {data?.owner_nation ? data?.owner_nation : "Байхгүй"}
                      </div>
                    </Form.Item>
                  </div>
                  <div className="w-1/2 p-2">
                    <Form.Item label="Нэр">
                      <div className="-mt-5 font-bold">
                        {data?.owner_firstname
                          ? data?.owner_firstname
                          : "Байхгүй"}
                      </div>
                    </Form.Item>
                    <Form.Item label="Жолооны үнэмлэхний №">
                      <div className="-mt-5 font-bold">
                        {data?.owner_license ? data?.owner_license : "Байхгүй"}
                      </div>
                    </Form.Item>
                    <Form.Item label="Утасны №">
                      <div className="-mt-5 font-bold">
                        {data?.owner_phone ? data?.owner_phone : "Байхгүй"}
                      </div>
                    </Form.Item>
                  </div>
                </div>
              </Form>
              <Form
                className="bg-white "
                layout="vertical"
                initialValues={{
                  law: data?.law ? data?.law : "",
                  mark: data?.mark ? data?.mark : "",
                  model: data?.model ? data?.model : "",
                  owner_type: data?.owner_type ? data?.owner_type : "",
                  number: data?.number ? data?.number : "",
                  cabin_number: data?.cabin_number ? data?.cabin_number : "",
                  color: data?.color ? data?.color : "",
                  // description: "",
                }}
                onFinish={onFinish}
              >
                <Typography.Title
                  className="border-l-4 border-def-primary pl-2"
                  level={5}
                  style={{ margin: 0 }}
                >
                  Тээврийн хэрэгслийн мэдээлэл
                </Typography.Title>
                <div className="flex flex-row">
                  <div className="w-1/2 p-2">
                    <Form.Item label="Марк" name="mark">
                      <Input placeholder="Бичих" disabled={!edit} />
                    </Form.Item>
                    <Form.Item label="Улсын №" name="number">
                      <Input placeholder="Бичих" disabled={!edit} />
                    </Form.Item>
                    <Form.Item label="Өнгө" name="color">
                      <Input placeholder="Бичих" disabled={!edit} />
                    </Form.Item>
                  </div>
                  <div className="w-1/2 p-2">
                    <Form.Item label="Модел" name="model">
                      <Input placeholder="Бичих" disabled={!edit} />
                    </Form.Item>
                    <Form.Item label="Арлын №" name="cabin_number">
                      <Input placeholder="Бичих" disabled={!edit} />
                    </Form.Item>
                    <Form.Item label="Эзэмшил" name="owner_type">
                      <Input placeholder="Бичих" disabled={!edit} />
                    </Form.Item>
                    {!edit ? (
                      !data?.law && (
                        <Button
                          loading={loading}
                          type="primary"
                          onClick={() => setedit(true)}
                          className="w-full"
                          ghost
                        >
                          Тээврийн хэрэгслийн мэдээлэл засах
                        </Button>
                      )
                    ) : (
                      <Form.Item>
                        <Button
                          loading={loading}
                          type="primary"
                          htmlType="submit"
                          className="w-full"
                        >
                          Хадгалах
                        </Button>
                      </Form.Item>
                    )}
                  </div>
                </div>
              </Form>
            </div>
            <Typography.Title
              level={5}
              className="border-l-4 border-def-primary pl-2 mb-4"
              style={{ margin: 0 }}
            >
              Зөрчил
            </Typography.Title>
            <Form
              className="bg-white "
              layout="vertical"
              initialValues={{
                law: data?.law ? data?.law : "",
                reason_type: data?.reason_type ? data?.reason_type : "",
              }}
              onFinish={onVerify}
            >
              <Form.Item
                label="Зөрчилийн ангилал"
                name="reason_type"
                rules={[
                  {
                    required: true,
                    message: "Зөрчилийн ангилал сонгоно уу.",
                  },
                ]}
              >
                <Select
                  disabled={data?.reason_type ? true : false}
                  list={TYPE}
                  method="POST"
                />
              </Form.Item>
              <Form.Item label="Зөрчилийн зүйл заалт" name="law">
                <SelectLaw
                  disabled={data?.law ? true : false}
                  url={"/law/table"}
                  method="POST"
                />
              </Form.Item>
              {!(data?.law || data?.reason_type) && (
                <div className="flex  w-full justify-end items-end px-2">
                  <Form.Item>
                    <Button
                      loading={loading}
                      type="primary"
                      htmlType="submit"
                      className="w-full"
                    >
                      Хадгалах
                    </Button>
                  </Form.Item>
                </div>
              )}
            </Form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Forms;
