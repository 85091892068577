import React, { useState } from "react";
import menu from "./menu";
import routes from "./routes";
import { UserOutlined, LoginOutlined } from "@ant-design/icons";
import { Avatar, Badge, Spin } from "antd";
import { NavLink, Route, Routes } from "react-router-dom";
import { useAuth } from "../utils/auth";
import Swal from "sweetalert2";
const App = () => {
  const [showNoti, setshowNoti] = useState(false);
  const { user, logout } = useAuth();

  if (!user)
    return (
      <div className="flex w-full h-screen justify-center align-middle items-center ">
        <Spin size="large" />
      </div>
    );

  return (
    <div className="flex flex-row bg-def-gray ">
      <div className="flex flex-row ">
        <div className="flex flex-col mt h-screen bg-white border-r">
          <div
            className="flex flex-row justify-center py-3 align-middle items-center "
            onClick={() => {}}
          >
            <img
              src="../img/atl.png"
              style={{ width: 40, height: 40 }}
              alt=""
            />
          </div>
          <div className="flex flex-col justify-between  h-full">
            <div>
              {menu[user?.role]?.map(({ label, icon, url }, index) => {
                return (
                  <NavLink
                    to={url}
                    key={index}
                    className={({ isActive }) =>
                      `${
                        isActive ? `bg-def-primary text-white` : `text-black`
                      } flex flex-row align-middle items-center p-4 hover:bg-def-primary hover:text-white`
                    }
                  >
                    {icon}
                    {/* <div className="text-lg pl-3">{label}</div> */}
                  </NavLink>
                );
              })}
            </div>
            <div
              className="flex flex-row justify-center py-5 items-center"
              onClick={() => {
                Swal.fire({
                  title: "Системээс гарах уу.?",
                  showDenyButton: true,
                  confirmButtonText: "Тийм",
                  denyButtonText: `Үгүй`,
                }).then(async (result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                    logout();
                  }
                });
              }}
            >
              <LoginOutlined
                color="red"
                style={{ color: "red", fontSize: 20 }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <header className="w-full ">
          <div className=" flex flex-row shadow-md p-2 justify-between bg-white h-16 items-center">
            <div className="mx-4 text-base  font-bold">
              Камерын хяналтын систем
            </div>
            <div className="flex flex-row items-center">
              <div className="mx-4 text-sm font-semibold">{user.phone}</div>
              <Badge>
                <Avatar
                  shape="circle"
                  onClick={() => {
                    setshowNoti(!showNoti);
                  }}
                  icon={<UserOutlined />}
                  style={{ backgroundColor: "#1890ff" }}
                />
              </Badge>
              {/* {showNoti && <Notifications />} */}
            </div>
          </div>
        </header>
        <div className="flex flex-col h-full bg-def-gray p-5 mt-1">
          <Routes>
            {routes[user.role]?.map(({ path, element }, index) => {
              return (
                <Route
                  key={index}
                  index={index === 0}
                  path={path}
                  element={element}
                />
              );
            })}
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default App;
