import React, { useState } from "react";
import Table from "../components/Table";
import { Button, Image } from "antd";
import { UserAddOutlined, FileAddOutlined } from "@ant-design/icons";
import { BsArrowRightCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import { DATE_FORMAT } from "../const";
import moment from "moment";
const List = () => {
  const [total, settotal] = useState();
  // <Image width={50} height={50} src={photo_1_url} alt="" />
  const findColumns = [
    {
      placeholder: "Хууль",
      index: "law",
      type: "text",
    },
    {
      placeholder: "Зүйл,заалт",
      index: "section",
      type: "text",
    },
    {
      placeholder: "Торгох дүн",
      index: "amount",
    },
    {
      placeholder: "Утга",
      index: "text",
      type: "text",
    },
  ];
  const columns = [
    {
      title: "Хууль",
      dataIndex: "law",
    },
    {
      title: "Зүйл,заалт",
      dataIndex: "section",
    },
    {
      title: "Торгох дүн",
      dataIndex: "amount",
      render: (amount) => <div>{`${amount}₮`}</div>,
    },
    {
      title: "Утга",
      dataIndex: "text",
    },

    {
      title: "",
      dataIndex: "_id",

      render: (_id) => (
        <Link
          to={`edit/${_id}`}
          style={{
            minWidth: 30,
            minHeight: 30,
            justifyContent: "center",
            alignItems: "center",
            color: "#1890ff",
            alignSelf: "center",
          }}
        >
          <BsArrowRightCircle size={20} />
        </Link>
      ),
    },
  ];
  return (
    <div className="flex flex-col  p-2">
      <div className="flex flex-row justify-between items-center bg-white p-3 border-l-8 border-def-primary mb-6">
        <div className="text-base font-semibold">Хуулийн жагсаалт</div>
        <div className="flex flex-row">
          <div className="p-2 font-semibold text-right ">Нийт : {total}</div>
          <Link
            to={`new/`}
            style={{
              minWidth: 30,
              minHeight: 30,
              justifyContent: "center",
              alignItems: "center",
              color: "#1890ff",
              alignSelf: "center",
            }}
          >
            <Button shape="circle" icon={<FileAddOutlined />} size="middle" />
          </Link>
        </div>
      </div>

      <Table
        url={"/law/table"}
        columns={columns}
        setTotal={settotal}
        findColumns={findColumns}
      />
    </div>
  );
};

export default List;
