//Хэрэглэгчид
import UserForm from "../user/Form";
import UserList from "../user/List";
//Case
import CaseForm from "../case/Form";
import CaseNew from "../case/New";
import CaseList from "../case/List";
//Law
import LawForm from "../law/Form";
import LawList from "../law/List";
//Camera
import CameraForm from "../camera/Form";
import CameraList from "../camera/List";
//Sync
import Sync from "../sync/List";
const Superior = [
  {
    path: "user",
    element: <UserList />,
  },
  {
    path: "user/new",
    element: <UserForm />,
  },
  {
    path: "user/edit/:id",
    element: <UserForm />,
  },
  {
    path: "case",
    element: <CaseList />,
  },
  {
    path: "case/new",
    element: <CaseNew />,
  },
  {
    path: "case/edit/:id",
    element: <CaseForm />,
  },
  {
    path: "law",
    element: <LawList />,
  },
  {
    path: "law/new",
    element: <LawForm />,
  },
  {
    path: "law/edit/:id",
    element: <LawForm />,
  },
  {
    path: "camera",
    element: <CameraList />,
  },
  {
    path: "camera/new",
    element: <CameraForm />,
  },
  {
    path: "camera/edit/:id",
    element: <CameraForm />,
  },
  {
    path: "sync",
    element: <Sync />,
  },
];
const Operator = [
  {
    path: "case",
    element: <CaseList />,
  },
  {
    path: "case/new",
    element: <CaseForm />,
  },
  {
    path: "case/edit/:id",
    element: <CaseForm />,
  },
];
const Admin = [
  {
    path: "user",
    element: <UserList />,
  },
  {
    path: "user/new",
    element: <UserForm />,
  },
  {
    path: "user/edit/:id",
    element: <UserForm />,
  },
  {
    path: "case",
    element: <CaseList />,
  },
  {
    path: "case/new",
    element: <CaseForm />,
  },
  {
    path: "case/edit/:id",
    element: <CaseForm />,
  },
];
const routes = {
  superior: Superior,
  admin: Admin,
  operator: Operator,
};

export default routes;
