import React, { useState } from "react";
import Table from "../components/Table";
import { Button } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import { BsArrowRightCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import { DATE_FORMAT } from "../const";
import moment from "moment";
const List = () => {
  const [total, settotal] = useState();
  const findColumns = [
    {
      placeholder: "Утасны дугаар",
      index: "phone",
      type: "text",
    },
  ];
  const columns = [
    {
      title: "Утасны дугаар",
      dataIndex: "phone",
    },
    {
      title: "Хэрэглэгчийн эрх",
      dataIndex: "role",
      render: (role) => (
        <div>
          {role === "superior"
            ? "Супер админ"
            : role === "admin"
            ? "Админ"
            : "Оператор"}
        </div>
      ),
    },
    {
      title: "Бүртгэгдсэн огноо",
      dataIndex: "created",

      render: (created) => (
        <div className="">{moment(created).format(DATE_FORMAT)}</div>
      ),
    },
    {
      title: "Төлөв",
      dataIndex: "active",
      width: 100,
      render: (active) => (
        <div className="justify-center  align-middle items-center flex">
          <div
            className={`w-3 h-3 rounded-full ${
              active ? "bg-green-500" : "bg-red-500"
            }`}
          />
        </div>
      ),
    },

    {
      title: "",
      dataIndex: "_id",

      render: (_id) => (
        <Link
          to={`edit/${_id}`}
          style={{
            minWidth: 30,
            minHeight: 30,
            justifyContent: "center",
            alignItems: "center",
            color: "#1890ff",
            alignSelf: "center",
          }}
        >
          <BsArrowRightCircle size={20} />
        </Link>
      ),
    },
  ];
  return (
    <div className="flex flex-col  p-2">
      <div className="flex flex-row justify-between items-center bg-white p-3 border-l-8 border-def-primary mb-6">
        <div className="text-base font-semibold">Хэрэглэгчийн удирдлага</div>
        <div className="flex flex-row">
          <div className="p-2 font-semibold text-right ">Нийт : {total}</div>
          <Link
            to={`new/`}
            style={{
              minWidth: 30,
              minHeight: 30,
              justifyContent: "center",
              alignItems: "center",
              color: "#1890ff",
              alignSelf: "center",
            }}
          >
            <Button shape="circle" icon={<UserAddOutlined />} size="middle" />
          </Link>
        </div>
      </div>

      <Table
        url={"/user/table"}
        columns={columns}
        setTotal={settotal}
        findColumns={findColumns}
      />
    </div>
  );
};

export default List;
