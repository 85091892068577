import moment from "moment";
import React from "react";
import { DATE_FORMAT } from "../const";
import { SyncOutlined } from "@ant-design/icons";
import Table from "../components/Table";

import { Button } from "antd";
import { mainApi } from "../utils/api";
const columns = [
  {
    title: "Систем",
    dataIndex: "from",
  },
  {
    title: "Татсан огноо",
    dataIndex: "date",
    render: (date) => (
      <div className="">{moment(date).format(DATE_FORMAT)}</div>
    ),
  },
  {
    title: "Нийт",
    dataIndex: "total",
  },

  {
    title: "Эхлэсэн огноо",
    dataIndex: "started",
    render: (started) => (
      <div className="">{moment(started).format(DATE_FORMAT)}</div>
    ),
  },
  {
    title: "Дууссан огноо",
    dataIndex: "ended",
    render: (ended) => (
      <div className="">{moment(ended).format(DATE_FORMAT)}</div>
    ),
  },
  {
    title: "Төлөв",
    dataIndex: "status",
    width: 100,
    render: (status) => (
      <div className="justify-center  align-middle items-center flex">
        <div
          className={`w-3 h-3 rounded-full ${
            status === "success" ? "bg-green-500" : "bg-red-500"
          }`}
        />
      </div>
    ),
  },
  {
    title: "",
    render: ({ from, date, _id }) => (
      <Button
        onClick={() => {
          fetch(from, date);
        }}
        type="danger"
        icon={<SyncOutlined />}
        size={20}
      />
    ),
  },
];
const fetch = async (company, date) => {
  await mainApi({
    url: `/sync/${company}/${date}`,
    method: "GET",
  });
};
const List = () => {
  return (
    <div className="flex flex-col  p-2">
      <div className="flex flex-row justify-between items-center bg-white p-3 border-l-8 border-def-primary">
        <div className="text-base font-semibold">Мэдээлэл татсан жагсаалт</div>
        <div className="flex-row">
          {/* <Button type="danger" icon={<SyncOutlined />} size={20} /> */}
        </div>
      </div>
      <Table url={"/sync/table"} sort={{ _id: -1 }} columns={columns} />
    </div>
  );
};

export default List;
