import React, { useEffect, useState, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GetFromStorage, RemoveFromStorage, SaveToStorage } from "./storage";
import { mainApi } from "./api";
import { errorAlert } from "./alert";

window.addEventListener("storage", function (event) {
  if (event.key === "logout-event") {
    window.location = "/auth/login";
    window.close();
  }
});

const AuthContext = createContext({
  user: null,
  login: () => {},
  logout: () => {},
});

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState();

  const login = async (instance) => {
    setInstance(instance);

    navigate(`/${instance.user.role}`);
  };

  const logout = () => {
    setInstance();
  };

  const setInstance = (instance) => {
    if (instance) {
      SaveToStorage("refreshToken", instance.refreshToken);
      SaveToStorage("accessToken", instance.accessToken);
      SaveToStorage("user", instance.user);
      setUser(instance.user);
    } else {
      RemoveFromStorage("refreshToken");
      RemoveFromStorage("accessToken");
      RemoveFromStorage("user");
      setUser(null);
      navigate("/");
    }
  };

  useEffect(() => {
    const refreshUserToken = async (token) => {
      if (token) {
        try {
          const response = await mainApi({
            method: "POST",
            url: "/user/refresh",
            data: {
              token,
            },
          });
          response && setInstance(response.data);
        } catch (error) {
          errorAlert("error.network");
        }
      }
    };

    refreshUserToken(GetFromStorage("refreshToken"));
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
export default AuthProvider;
