import {
  OrderedListOutlined,
  TeamOutlined,
  CloudSyncOutlined,
  SortAscendingOutlined,
} from "@ant-design/icons";

import { VscLaw } from "react-icons/vsc";
import { CgCamera } from "react-icons/cg";
import { HiUserGroup } from "react-icons/hi";
import { AiOutlineCloudSync } from "react-icons/ai";

const Superior = [
  {
    icon: <OrderedListOutlined style={{ fontSize: 20 }} />,
    url: "case",
  },
  {
    icon: <HiUserGroup style={{ fontSize: 20 }} />,
    url: "user",
  },
  {
    icon: <AiOutlineCloudSync size={20} />,
    url: "sync",
  },
  {
    icon: <VscLaw size={20} />,
    url: "law",
  },
  {
    icon: <CgCamera size={20} />,
    url: "camera",
  },
];
const Operator = [
  {
    icon: <OrderedListOutlined style={{ fontSize: 20 }} />,
    url: "case",
  },
];
const Admin = [
  {
    icon: <OrderedListOutlined style={{ fontSize: 20 }} />,
    url: "case",
  },
  {
    icon: <TeamOutlined style={{ fontSize: 20 }} />,
    url: "user",
  },
];
const menu = {
  superior: Superior,
  admin: Admin,
  operator: Operator,
};

export default menu;
