import React, { useState } from "react";
import Table from "../components/Table";
import { Button, Image } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import { BsArrowRightCircle } from "react-icons/bs";
import { MdLocalPolice } from "react-icons/md";
import { Link } from "react-router-dom";
import { DATE_FORMAT } from "../const";
import moment from "moment";
import { mainApi } from "../utils/api";
import Swal from "sweetalert2";
import { successAlert } from "../utils/alert";
import { useAuth } from "../utils/auth";
const List = () => {
  const [total, settotal] = useState();
  // <Image width={50} height={50} src={photo_1_url} alt="" />
  const findColumns = [
    {
      placeholder: "Улсын №",
      index: "number",
      type: "text",
    },
    {
      placeholder: "Зөрчил",
      index: "violation",
      type: "list",
    },
    {
      placeholder: "Хурд",
      index: "speed",
      type: "text",
    },
    {
      placeholder: "Зөрчилын огноо",
      index: "case_date",
      type: "date",
    },
    {
      placeholder: "Чиглэл",
      index: "camera_host",
      type: "text",
    },
    {
      placeholder: "Марк",
      index: "mark",
      type: "text",
    },
    {
      placeholder: "Модел",
      index: "model",
      type: "text",
    },
    {
      placeholder: "Өнгө",
      index: "color",
      type: "text",
    },
  ];
  const columns = [
    {
      title: "Зураг",

      render: ({ photo_1_url, from }) =>
        from === "ORGIL" ? (
          <img
            width={60}
            height={60}
            src={`https://polite-api.sys.mn/remote-image?url=${photo_1_url}`}
            alt=" "
          ></img>
        ) : (
          <img
            width={60}
            height={60}
            crossOrigin="anonymous"
            src={photo_1_url}
            alt=" "
          ></img>
        ),
    },
    {
      title: "Улсын №",
      render: ({ number, _id, status }) => {
        return (
          <Link
            to={`edit/${_id}`}
            target="_blank"
            style={{
              justifyContent: "center",
              alignItems: "center",

              alignSelf: "center",
            }}
          >
            <div
              className={`underline font-semibold ${
                status == "penalty"
                  ? `text-def-green`
                  : status == "verified"
                  ? `text-yellow-500`
                  : `text-def-primary`
              }`}
            >
              {number}
            </div>
          </Link>
        );
      },
    },
    {
      title: "Зөрчил",
      render: ({ violation, status }) => (
        <div>{violation == "Speed-Avg" ? "Хурд хэтрүүлсэн" : violation}</div>
      ),
    },
    {
      title: "Хурд",
      render: ({ speed, status }) => <div>{speed}</div>,
    },
    {
      title: "Зөрчилын огноо",
      dataIndex: "case_date",

      render: (case_date) => (
        <div className="">{moment(case_date).format(DATE_FORMAT)}</div>
      ),
    },
    {
      title: "Чиглэл",
      dataIndex: "camera_host",

      render: (camera_host) => <div className="">{camera_host}</div>,
    },
    {
      title: "Марк, модел, өнгө",
      render: ({ color, mark, model }) => (
        <div className="">
          <div>{`${mark} - ${model}`}</div>

          <div>{color}</div>
        </div>
      ),
    },
  ];
  const { user } = useAuth();
  const simulate = async () => {
    Swal.fire({
      title: "Тестээр мессеж илгээх үү.?",
      showDenyButton: true,
      confirmButtonText: "Тийм",
      denyButtonText: `Үгүй`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        try {
          const res = await mainApi({
            url: "/public/simulate",
            method: "GET",
          });
          if (res.status == 200) {
            successAlert("", "Амжилттай ");
          }
        } catch (err) {}
      }
    });
  };
  return (
    <div className="flex flex-col  p-2">
      <div className="flex flex-row justify-between items-center bg-white p-3 border-l-8 border-def-primary mb-6">
        <div className="text-base font-semibold">Зөрчилийн мэдээлэл</div>
        <div className="flex flex-row">
          <div className="p-2 font-semibold text-right ">Нийт : {total}</div>
          {user.role == "superior" && (
            <Button
              shape="circle"
              icon={<MdLocalPolice className="ml-2" />}
              size="middle"
              danger
              onClick={simulate}
            />
          )}

          <Link
            to={`new/`}
            style={{
              minWidth: 30,
              minHeight: 30,
              marginLeft: 10,
              justifyContent: "center",
              alignItems: "center",
              color: "#1890ff",
              alignSelf: "center",
            }}
          >
            <Button shape="circle" icon={<UserAddOutlined />} size="middle" />
          </Link>
        </div>
      </div>

      <Table
        url={"/case/table"}
        sort={{ _id: -1 }}
        columns={columns}
        setTotal={settotal}
        findColumns={findColumns}
      />
    </div>
  );
};

export default List;
