import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactInputVerificationCode from "react-input-verification-code";
import styled from "styled-components";
import { Button, Spin } from "antd";
import { errorAlert, successAlert } from "../utils/alert";
import PDF from "./PDF";
import { API_ROOT } from "../const";
const StyledReactInputVerificationCode = styled.div`
  --ReactInputVerificationCode-itemWidth: 40px;
  --ReactInputVerificationCode-itemHeight: 48px;
  --ReactInputVerificationCode-itemSpacing: 8px;

  .ReactInputVerificationCode__item {
    font-size: 16px;
    font-weight: 500;
    color: #a71930;

    border: 1px solid
      ${({ isInvalid }) => (isInvalid ? "#EF6C65" : "rgba(28, 30, 60, 0.4)")};
    border-radius: 4px;
    box-shadow: none;
  }

  .ReactInputVerificationCode__item.is-active {
    box-shadow: none;
    border: 1px solid #a71930;
  }
`;
const Case = () => {
  const { id } = useParams();
  const [captcha, setCaptcha] = useState();
  const [loading, setloading] = useState(false);
  const [checkLoading, setcheckLoading] = useState(false);
  const [data, setdata] = useState();
  const [penalty, setpenalty] = useState();
  const [hide, sethide] = useState(false);

  console.log("🚀 ~ file: Case.js ~ line 6 ~ Case ~ id", id);
  useEffect(() => {
    id && fetch();
  }, []);
  const fetch = async () => {
    try {
      setloading(true);
      const res = await axios({
        url: `${API_ROOT}/public/penalty/check/${id}`,
        method: "get",
      });
      console.log("res.data", res.data);
      setdata(res.data);
      setloading(false);
    } catch (err) {
      console.log(
        "🚀 ~ file: Case.js ~ line 51 ~ fetch ~ err",
        err?.response?.data?.message
      );
      const message = err?.response?.data?.message;
      errorAlert(message);
    }
  };
  const confirm = async (result) => {
    try {
      sethide(true);
      const res = await axios({
        url: `${API_ROOT}/public/penalty/response`,
        method: "put",
        data: {
          _id: data._id,
          type: result,
        },
      });
      if (res.status == 200) successAlert("", "Амжилттай хадгалдлаа");
    } catch (err) {
      console.log(
        "🚀 ~ file: Case.js ~ line 51 ~ fetch ~ err",
        err?.response?.data?.message
      );
      const message = err?.response?.data?.message;
      errorAlert(message);
      setcheckLoading(false);
    }
  };
  const seen = async () => {
    try {
      setcheckLoading(true);
      const res = await axios({
        url: `${API_ROOT}/public/penalty/seen`,
        method: "put",
        data: {
          _id: data._id,
          captcha: captcha,
        },
      });
      console.log("res.data", res.data);
      setpenalty(res.data);
      setcheckLoading(false);
    } catch (err) {
      console.log(
        "🚀 ~ file: Case.js ~ line 51 ~ fetch ~ err",
        err?.response?.data?.message
      );
      const message = err?.response?.data?.message;
      errorAlert(message);
      setcheckLoading(false);
    }
  };
  return (
    <div className="flex bg-def-primary w-full h-full min-h-screen	 justify-center items-center">
      {loading ? (
        <Spin size="large" />
      ) : penalty ? (
        <div className="bg-def-primary ">
          <PDF url={penalty?.pdf} />
          {!hide && (
            <div className="flex flex-col bg-white h-32 mt-10 mb-10 p-10 rounded shadow-xl items-center">
              <div className="mb-4">
                Та уг торгуулийг хүлээн зөвшөөрч байна уу.?
              </div>
              <div className="flex justify-between">
                <Button
                  className="w-32 mx-4"
                  danger
                  onClick={() => confirm("complain")}
                >
                  Үгүй
                </Button>
                <button
                  className="w-32 mx-4 text-green-600 border-green-600 border "
                  onClick={() => confirm("confirm")}
                >
                  Тийм
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col bg-white h-64 p-10 rounded shadow-xl items-center">
          <div className="mb-4">
            Регистерийн дугаарын сүүлийн 6 орон оруулна уу.
          </div>
          <div className="mb-4">{`${data?.register}${captcha}`}</div>
          <StyledReactInputVerificationCode>
            <ReactInputVerificationCode
              autoFocus={true}
              placeholder="*"
              length={6}
              style={{ size: 30 }}
              value={captcha}
              onChange={setCaptcha}
            />
          </StyledReactInputVerificationCode>
          {checkLoading ? (
            <Spin size="default" />
          ) : (
            <Button className="w-32 mt-6" danger onClick={seen}>
              Шалгах
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default Case;
