import packageJson from "../package.json";
// import { PRO_ROOT } from "./env";

export const DEBUG = process.env.NODE_ENV === "development";
export const APP_NAME = "eTaxi.mn";

// export const API_ROOT = DEBUG ? DEV_URL : PRO_URL;
// export const API_ROOT = "http://192.168.0.114:5140/";
export const API_ROOT = "https://polite-api.sys.mn/";
export const VERSION = packageJson.version;
export const YEAR = () => {
  return new Date().getFullYear();
};
export const DATE_FORMAT = "yyyy-MM-DD HH:mm:ss";
export const timeOut = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
export const TYPE = [
  { value: 1, label: "1. Шууд торгох боломжтой" },
  { value: 2, label: "2. Нэмэлт ажиллагаа явуулах" },
  {
    value: 3,
    label: "3.Шаардлага хангаагүй зөрчлийн шинжгүй, зөрчил нотлогдоогүй",
  },
];
export const VIOLATION = [
  { value: "ОНОШИЛГООНЫ_ЗӨРЧИЛ", label: "ОНОШИЛГООНЫ_ЗӨРЧИЛ" },
  { value: "Speed-Avg", label: "Хурд хэтрүүлсэн" },
];
