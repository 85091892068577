import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  Spin,
  Select,
  Image,
  Typography,
  InputNumber,
} from "antd";
import { AiFillLock } from "react-icons/ai";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/auth";
// import Select from "../components/Select";
import { mainApi } from "../utils/api";
import { successAlert } from "../utils/alert";
import Swal from "sweetalert2";
import FileUpload from "../components/Upload";
import moment from "moment";
import { DATE_FORMAT } from "../const";
const { Paragraph } = Typography;
const { TextArea } = Input;

const { Option } = Select;

const Forms = () => {
  const { id } = useParams();
  const { user } = useAuth();

  const history = useNavigate();
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState(null);

  useEffect(() => {
    id && fetch();
  }, []);
  const fetch = async () => {
    try {
      setloading(true);
      let res = await mainApi({
        url: `/camera/${id}`,
        method: "GET",
      });

      setdata(res.data);

      setloading(false);
    } catch (err) {
      setloading(false);
    }
  };
  const onFinish = async (data) => {
    try {
      setloading(true);
      const res = await mainApi({
        method: id ? "PUT" : "POST",
        url: id ? `/camera/${id}` : "/camera",
        data: data,
      });
      if (res.status == 200) {
        history(-1);
        successAlert("", "Амжилттай хадгалагдлаа");
      }
      setloading(false);
    } catch (err) {
      setloading(false);
    }
    // onFinish;
  };

  return (
    <div className="flex flex-col  p-2">
      <div className="flex justify-between items-center p-3 border-l-8 border-def-primary mx-2">
        <div className="text-sm font-semibold">Камерын мэдээлэл</div>
      </div>
      <div className="flex w-full justify-between p-2 my-4">
        <Button
          danger
          icon={<ArrowLeftOutlined />}
          size="middle"
          onClick={() => history(-1)}
        />
      </div>
      {loading ? (
        <div className="flex w-full h-32 justify-center items-center align-middle">
          <Spin size="large" />
        </div>
      ) : (
        <Form
          className="bg-white "
          layout="vertical"
          initialValues={{
            aimag: data?.aimag ? data?.aimag : "",
            sum: data?.sum ? data?.sum : "",
            name: data?.name ? data?.name : "",
            code: data?.code ? data?.code : "",
            account: data?.account ? data?.account : "",
            aimag_zip: data?.aimag_zip ? data?.aimag_zip : "",
            sum_zip: data?.sum_zip ? data?.sum_zip : "",
          }}
          onFinish={onFinish}
        >
          <div className="p-4 flex flex-wrap">
            <div className="w-1/2 p-2">
              <Form.Item label="Аймаг" name="aimag">
                <Input placeholder="Бичих" required />
              </Form.Item>
              <Form.Item label="Сум" name="sum">
                <Input placeholder="Бичих" required />
              </Form.Item>
              <Form.Item className="w-full" label="Камерны нэршил" name="name">
                <Input placeholder="Бичих" required />
              </Form.Item>
              <Form.Item className="w-full" label="Камерны код" name="code">
                <Input placeholder="Бичих" required />
              </Form.Item>
            </div>
            <div className="w-1/2 p-2 ">
              <Form.Item label="Данс" name="account">
                <Input placeholder="Бичих" />
              </Form.Item>
              <Form.Item label="Аймаг ZIP код" name="aimag_zip">
                <Input placeholder="Бичих" />
              </Form.Item>
              <Form.Item label="Сум ZIP код" name="sum_zip">
                <Input placeholder="Бичих" />
              </Form.Item>
            </div>
            {user.role === "superior" && (
              <div className="flex  w-full justify-end items-end px-2">
                <Form.Item>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="w-full"
                    danger
                  >
                    Хадгалах
                  </Button>
                </Form.Item>
              </div>
            )}
          </div>
        </Form>
      )}
    </div>
  );
};

export default Forms;
