import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Spin } from "antd";
import "antd/dist/antd.css";
import App from "./layouts/App";
import Auth from "./layouts/Auth";
import AuthProvider from "./utils/auth";
import NotFound from "./pages/NotFound";
import Case from "./public/Case";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <React.StrictMode>
      <Suspense fallback={<Spin />}>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Navigate to="auth/login" />} />
            <Route path="auth" element={<Auth />}>
              {/* <Route path="forget" element={<Forget />} /> */}
              <Route path="login" element={<Auth />} />
              <Route path="logout" element={<Auth from="inside" />} />
              {/* <Route path="recover" element={<Recover />} /> */}
              {/* <Route path="register" element={<Register />} /> */}
            </Route>
            <Route path="superior/*" element={<App />} />
            <Route path="admin/*" element={<App />} />
            <Route path="operator/*" element={<App />} />
            <Route path="p/:id" element={<Case />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AuthProvider>
      </Suspense>
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
