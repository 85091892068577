import React from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="flex">
      <img src="../img/login.png" alt="" />

      <div className="absolute flex flex-col w-full h-screen justify-center align-middle items-center">
        <div className="text-white font-bold text-8xl">404</div>
        <div className="text-white m-3 text-xl font-semibold">
          Хуудас олдсонгүй
        </div>
        <button
          className="flex flex-row"
          type="button"
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowLeftOutlined
            className="text-lg mr-1"
            style={{ color: "#fff" }}
          />
          <div className="text-white -mt-1">Буцах</div>
        </button>
      </div>
    </div>
  );
};

export default NotFound;
