import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  Spin,
  Select,
  Image,
  Typography,
  InputNumber,
} from "antd";
import { AiFillLock } from "react-icons/ai";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/auth";
// import Select from "../components/Select";
import { mainApi } from "../utils/api";
import { successAlert } from "../utils/alert";
import Swal from "sweetalert2";
import FileUpload from "../components/Upload";
import moment from "moment";
import { DATE_FORMAT } from "../const";
const { Paragraph } = Typography;
const { TextArea } = Input;

const { Option } = Select;

const Forms = () => {
  const { id } = useParams();
  const { user } = useAuth();

  const history = useNavigate();
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState(null);

  useEffect(() => {
    id && fetch();
  }, []);
  const fetch = async () => {
    try {
      setloading(true);
      let res = await mainApi({
        url: `/law/${id}`,
        method: "GET",
      });

      setdata(res.data);

      setloading(false);
    } catch (err) {
      setloading(false);
    }
  };
  const onFinish = async (data) => {
    try {
      setloading(true);
      const res = await mainApi({
        method: id ? "PUT" : "POST",
        url: id ? `/law/${id}` : "/law",
        data: data,
      });
      if (res.status == 200) {
        history(-1);
        successAlert("", "Амжилттай хадгалагдлаа");
      }
      setloading(false);
    } catch (err) {
      setloading(false);
    }
    // onFinish;
  };

  return (
    <div className="flex flex-col  p-2">
      <div className="flex justify-between items-center p-3 border-l-8 border-def-primary mx-2">
        <div className="text-sm font-semibold">Хуулийн мэдээлэл</div>
      </div>
      <div className="flex w-full justify-between p-2 my-4">
        <Button
          danger
          icon={<ArrowLeftOutlined />}
          size="middle"
          onClick={() => history(-1)}
        />
      </div>
      {loading ? (
        <div className="flex w-full h-32 justify-center items-center align-middle">
          <Spin size="large" />
        </div>
      ) : (
        <Form
          className="bg-white "
          layout="vertical"
          initialValues={{
            law: data?.law ? data?.law : "",
            section: data?.section ? data?.section : "",
            amount: data?.amount ? data?.amount : "",
            text: data?.text ? data?.text : "",
            police_law: data?.police_law ? data?.police_law : "",
            police_section: data?.police_section ? data?.police_section : "",
          }}
          onFinish={onFinish}
        >
          <div className="p-4 flex flex-wrap">
            <div className="w-1/2 p-2">
              <Typography.Title
                className="border-l-4 border-def-primary pl-2"
                level={5}
                style={{ margin: 0 }}
              >
                Хуулийн мэдээлэл
              </Typography.Title>
              <Form.Item label="Хууль" name="law">
                <Input placeholder="Бичих" required />
              </Form.Item>
              <Form.Item label="Зүйл,заалт" name="section">
                <Input placeholder="Бичих" required />
              </Form.Item>
              <Form.Item className="w-full" label="Торгох дүн" name="amount">
                <InputNumber prefix="₮" style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                className="w-full"
                label="Торгох дүн бичгээр"
                name="amount_text"
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item label="Утга" name="text">
                <TextArea rows={5} />
              </Form.Item>
            </div>
            <div className="w-1/2 p-2 ">
              <Typography.Title
                className="border-l-4 border-def-primary pl-2"
                level={5}
                style={{ margin: 0 }}
              >
                Цагдаагийн системийн мэдээлэл
              </Typography.Title>
              <Form.Item label="Хууль" name="police_law">
                <Input placeholder="Бичих" />
              </Form.Item>
              <Form.Item label="Зүйл,заалт" name="police_section">
                <Input placeholder="Бичих" />
              </Form.Item>
            </div>
            {user.role === "superior" && (
              <div className="flex  w-full justify-end items-end px-2">
                <Form.Item>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="w-full"
                    danger
                  >
                    Хадгалах
                  </Button>
                </Form.Item>
              </div>
            )}
          </div>
        </Form>
      )}
    </div>
  );
};

export default Forms;
