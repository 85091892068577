import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDF = ({ url }) => {
  console.log("🚀 ~ file: PDF.js ~ line 4 ~ PDF ~ url", url);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    console.log(
      "🚀 ~ file: PDF.js ~ line 9 ~ onDocumentLoadSuccess ~ numPages",
      numPages
    );

    setNumPages(numPages);
  }

  return (
    <div>
      <Document
        file="https://polite-api.sys.mn/uploads/generated/20221019/6347207d954a301274248163.pdf"
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} />
      </Document>
    </div>
  );
};

export default PDF;
