import axios from "axios";
import { GetFromStorage, SaveToStorage, RemoveFromStorage } from "./storage";
import { API_ROOT, DEBUG } from "../const";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { Alert } from "./alert";
const mainApi = axios.create({
  baseURL: API_ROOT,
});
mainApi.interceptors.request.use((request) => {
  const accessToken = GetFromStorage("accessToken");

  console.log(
    "🚀 ~ file: api.js ~ line 16 ~ mainApi.interceptors.request.use ~ ",
    GetFromStorage("refreshToken")
  );
  if (accessToken) request.headers["Authorization"] = `Bearer ${accessToken}`;

  return request;
});
mainApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("🚀 ~ file: api.js ~ line 25 ~ error", error);

    if (error?.response?.status === 403) {
      RemoveFromStorage("refreshToken");
      RemoveFromStorage("accessToken");

      window.location = "/auth/login";
    }
    if (error?.response?.status === 400) {
      Alert.fire({
        html: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.response?.data,
        icon: "warning",
      });

      return Promise.resolve();
    }
    if (error?.response?.status === 404) {
      Alert.fire({
        html: "Хүсэлт олдсонгүй",
        icon: "warning",
      });

      return Promise.resolve();
    }
    if (error?.response?.status === 500) {
      DEBUG &&
        Alert.fire({
          html: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.response?.data,
          icon: "error",
        });

      return Promise.resolve();
    }
    if (error.message === "Network Error") {
      Alert.fire({
        html: "Сүлжээний алдаа",
        icon: "warning",
      });

      return Promise.resolve();
    }

    return Promise.reject(error);
  }
);

createAuthRefreshInterceptor(mainApi, (failedRequest) =>
  mainApi({
    method: "POST",
    url: "/user/refresh",
    data: { token: GetFromStorage("refreshToken") },
  })
    .then(({ data }) => {
      SaveToStorage("refreshToken", data.refreshToken);
      SaveToStorage("accessToken", data.accessToken);

      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + data.accessToken;

      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject(error);
    })
);

export { mainApi };
