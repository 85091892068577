import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Form, Input, Spin, DatePicker, Typography } from "antd";
import { AiFillLock } from "react-icons/ai";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/auth";
import SelectLaw from "../components/SelectLaw";
import Select from "../components/Select";
import { mainApi } from "../utils/api";
import { successAlert } from "../utils/alert";
import Swal from "sweetalert2";
import FileUpload from "../components/Upload";
import moment from "moment";
import { DATE_FORMAT, TYPE, VIOLATION } from "../const";
const { Paragraph } = Typography;
const { TextArea } = Input;

const Forms = () => {
  const { id } = useParams();
  const { user } = useAuth();

  const history = useNavigate();
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState(null);

  useEffect(() => {
    id && fetch();
  }, []);
  const fetch = async () => {
    try {
      setloading(true);
      let res = await mainApi({
        url: `/case/${id}`,
        method: "GET",
      });

      setdata(res.data);

      setloading(false);
    } catch (err) {
      setloading(false);
    }
  };
  const onFinish = async (data) => {
    try {
      setloading(true);
      const res = await mainApi({
        method: "POST",
        url: "/case",
        data: { ...data, from: "falcons" },
      });

      if (res.status == 200) {
        history(-1);
        successAlert("", "Амжилттай хадгалагдлаа");
      }

      setloading(false);
    } catch (err) {
      setloading(false);
    }

    // onFinish;
  };

  return (
    <div className="flex flex-col  p-2">
      <div className="flex justify-between items-center p-3 border-l-8 border-def-primary mx-2">
        <div className="text-sm font-semibold">Зөрчилийн мэдээлэл</div>
      </div>
      <div className="flex w-full justify-between p-2 my-4">
        <Button
          danger
          icon={<ArrowLeftOutlined />}
          size="middle"
          onClick={() => history(-1)}
        />
      </div>
      {loading ? (
        <div className="flex w-full h-32 justify-center items-center align-middle">
          <Spin size="large" />
        </div>
      ) : (
        <Form
          className="bg-white "
          layout="vertical"
          initialValues={{
            attachments: "",
            owner_lastname: "",
            owner_register: "",
            owner_nation: "",
            owner_firstname: "",
            owner_license: "",
            owner_phone: "",
            mark: "",
            number: "",
            color: "",
            model: "",
            cabin_number: "",
            owner_type: "",
            violation: "",
            reason_type: "",
            law: "",
            speed: "",
            case_date: "",
            camera_host: "",
          }}
          onFinish={onFinish}
        >
          <div className="p-4 flex flex-wrap">
            <div className="w-1/2 p-2">
              <Form.Item
                label="Зураг-1"
                name="attachments"
                // rules={[{ required: true }]}
              >
                <FileUpload
                  preview={true}
                  disabled={false}
                  limit={15}
                  count={2}
                  field="attachments"
                  root="photo"
                />
              </Form.Item>
            </div>
            <div className="w-1/2 p-2 ">
              <div>
                <Typography.Title
                  className="border-l-4 border-def-primary pl-2"
                  level={5}
                  style={{ margin: 0 }}
                >
                  Эзэмшигчийн мэдээлэл
                </Typography.Title>
                <div className="flex flex-row">
                  <div className="w-1/2 p-2">
                    <Form.Item
                      label="Эцэг/эх/-ийн нэр"
                      name="owner_lastname"
                      rules={[
                        {
                          required: true,
                          message: "Эцэг/эх/-ийн нэр бичнэ үү.",
                        },
                      ]}
                    >
                      <Input placeholder="Бичих" required />
                    </Form.Item>
                    <Form.Item
                      label="Регистрийн №"
                      name="owner_register"
                      rules={[
                        {
                          required: true,
                          message: "Зөрчил бичнэ үү.",
                        },
                      ]}
                    >
                      <Input placeholder="Бичих" required />
                    </Form.Item>
                    <Form.Item
                      label="Иргэншил"
                      name="owner_nation"
                      rules={[
                        {
                          required: true,
                          message: "Зөрчил бичнэ үү.",
                        },
                      ]}
                    >
                      <Input placeholder="Бичих" required />
                    </Form.Item>
                  </div>
                  <div className="w-1/2 p-2">
                    <Form.Item
                      label="Нэр"
                      name="owner_firstname"
                      rules={[
                        {
                          required: true,
                          message: "Зөрчил бичнэ үү.",
                        },
                      ]}
                    >
                      <Input placeholder="Бичих" required />
                    </Form.Item>
                    <Form.Item
                      label="Жолооны үнэмлэхний №"
                      name="owner_license"
                      rules={[
                        {
                          required: true,
                          message: "Зөрчил бичнэ үү.",
                        },
                      ]}
                    >
                      <Input placeholder="Бичих" required />
                    </Form.Item>
                    <Form.Item
                      label="Утасны №"
                      name="owner_phone"
                      rules={[
                        {
                          required: true,
                          message: "Зөрчил бичнэ үү.",
                        },
                      ]}
                    >
                      <Input placeholder="Бичих" required />
                    </Form.Item>
                  </div>
                </div>
                <Typography.Title
                  className="border-l-4 border-def-primary pl-2"
                  level={5}
                  style={{ margin: 0 }}
                >
                  Тээврийн хэрэгслийн мэдээлэл
                </Typography.Title>
                <div className="flex flex-row">
                  <div className="w-1/2 p-2">
                    <Form.Item
                      label="Марк"
                      name="mark"
                      rules={[
                        {
                          required: true,
                          message: "Зөрчил сонгоно уу.",
                        },
                      ]}
                    >
                      <Input placeholder="Бичих" required />
                    </Form.Item>
                    <Form.Item
                      label="Улсын №"
                      name="number"
                      rules={[
                        {
                          required: true,
                          message: "Зөрчил сонгоно уу.",
                        },
                      ]}
                    >
                      <Input placeholder="Бичих" required />
                    </Form.Item>
                    <Form.Item
                      label="Өнгө"
                      name="color"
                      rules={[
                        {
                          required: true,
                          message: "Зөрчил сонгоно уу.",
                        },
                      ]}
                    >
                      <Input placeholder="Бичих" required />
                    </Form.Item>
                  </div>
                  <div className="w-1/2 p-2">
                    <Form.Item
                      label="Модел"
                      name="model"
                      rules={[
                        {
                          required: true,
                          message: "Зөрчил сонгоно уу.",
                        },
                      ]}
                    >
                      <Input placeholder="Бичих" required />
                    </Form.Item>
                    <Form.Item
                      label="Арлын №"
                      name="cabin_number"
                      rules={[
                        {
                          required: true,
                          message: "Зөрчил сонгоно уу.",
                        },
                      ]}
                    >
                      <Input placeholder="Бичих" required />
                    </Form.Item>
                    <Form.Item
                      label="Эзэмшил"
                      name="owner_type"
                      rules={[
                        {
                          required: true,
                          message: "Зөрчил сонгоно уу.",
                        },
                      ]}
                    >
                      <Input placeholder="Бичих" required />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <Typography.Title
                level={5}
                className="border-l-4 border-def-primary pl-2 mb-4"
                style={{ margin: 0 }}
              >
                Зөрчил
              </Typography.Title>
              <Form.Item
                label="Зөрчил"
                name="violation"
                rules={[
                  {
                    required: true,
                    message: "Зөрчил сонгоно уу.",
                  },
                ]}
              >
                <Select list={VIOLATION} method="POST" />
              </Form.Item>
              <div className="flex">
                <div className="w-1/2">
                  <Form.Item
                    label="Зөрчлийн огноо"
                    name="case_date"
                    rules={[
                      {
                        required: true,
                        message: "Зөрчил сонгоно уу.",
                      },
                    ]}
                  >
                    <DatePicker
                      className="w-full"
                      showTime
                      format="YYYY-MM-DD HH:mm:ss"
                    />
                  </Form.Item>
                </div>
                <div className="w-1/2 pl-3">
                  <Form.Item label="Хурд" name="speed" rules={[]}>
                    <Input placeholder="Бичих" />
                  </Form.Item>
                </div>
              </div>
              <Form.Item
                label="Зөрчил гаргасан чиглэл"
                name="camera_host"
                rules={[]}
              >
                <Input placeholder="Бичих" />
              </Form.Item>
              <Form.Item
                label="Зөрчилийн ангилал"
                name="reason_type"
                rules={[
                  {
                    required: true,
                    message: "Зөрчилийн ангилал сонгоно уу.",
                  },
                ]}
              >
                <Select list={TYPE} method="POST" />
              </Form.Item>
              <Form.Item label="Зөрчилийн зүйл заалт" name="law">
                <SelectLaw value={data?.law} url={"/law/table"} method="POST" />
              </Form.Item>
            </div>
            {user.role === "superior" && (
              <div className="flex  w-full justify-end items-end px-2">
                <Form.Item>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="w-full"
                  >
                    Хадгалах
                  </Button>
                </Form.Item>
              </div>
            )}
          </div>
        </Form>
      )}
    </div>
  );
};

export default Forms;
