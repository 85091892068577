import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Form, Input, Spin, Select, Space } from "antd";
import { AiFillLock } from "react-icons/ai";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/auth";
// import Select from "../components/Select";
import { mainApi } from "../utils/api";
import { successAlert } from "../utils/alert";
import Swal from "sweetalert2";
import FileUpload from "../components/Upload";

const { Option } = Select;

const Forms = () => {
  const { id } = useParams();
  const { user } = useAuth();

  const history = useNavigate();
  const [loading, setloading] = useState(false);
  const [cameraLoader, setcameraLoader] = useState(false);
  const [data, setdata] = useState(null);
  const [cameraList, setcameraList] = useState([]);
  useEffect(() => {
    fetchConst();
    id && fetch();
  }, []);
  const fetch = async () => {
    try {
      setloading(true);
      let res = await mainApi({
        url: `/user/${id}`,
        method: "GET",
      });
      setdata(res.data);

      setloading(false);
    } catch (err) {
      setloading(false);
    }
  };
  const fetchConst = async () => {
    try {
      setcameraLoader(true);
      let temp = [];
      let res = await mainApi({
        url: `/camera/table`,
        method: "POST",
      });
      res?.data?.items?.map((row, index) => {
        temp.push({
          label: row?.name,
          value: row?._id,
          key: index,
        });
      });

      setcameraList(temp);
      setcameraLoader(false);
    } catch (err) {
      setcameraLoader(false);
    }
  };
  const onFinish = async (data) => {
    try {
      setloading(true);
      const res = await mainApi({
        method: id ? "PUT" : "POST",
        url: id ? `/user/${id}` : "/user",
        data: data,
      });

      if (res.status == 200) {
        history(-1);
        successAlert("", "Амжилттай хадгалагдлаа");
      }
      setloading(false);
    } catch (err) {
      setloading(false);
    }
    // onFinish;
  };
  const changePwd = async () => {
    Swal.fire({
      title: "Нууц үг дефаултаар солих уу?",
      showDenyButton: true,
      confirmButtonText: "Тийм",
      denyButtonText: `Үгүй`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setloading(true);
        await mainApi({
          method: "PATCH",
          url: `/user/${id}/password`,
        });
        history(-1);
        successAlert("", "Амжилттай хадгалагдлаа");
        setloading(false);
      }
    });
  };
  const selectProps = {
    mode: "multiple",
    style: { width: "100%" },
    options: cameraList,
    placeholder: "Сонгох...",
    maxTagCount: "responsive",
  };
  return (
    <div className="flex flex-col  p-2">
      <div className="flex justify-between items-center p-3 border-l-8 border-def-primary mx-2">
        <div className="text-sm font-semibold">Хэрэглэгчийн мэдээлэл</div>
      </div>
      <div className="flex w-full justify-between p-2 my-4">
        <Button
          danger
          icon={<ArrowLeftOutlined />}
          size="middle"
          onClick={() => history(-1)}
        />
        {/* 
        {id && (
          <div
            onClick={() => changePwd()}
            className="flex border p-2 hover:cursor-pointer bg-def-red items-center rounded-sm hover:bg-red-300 active:bg-red-600"
          >
            <AiFillLock
              style={{ fontSize: 14, color: "#fff", fontWeight: "normal" }}
            />
            <div className="ml-2 text-white text-xs">Нууц үг солих</div>
          </div>
        )} */}
      </div>
      {loading ? (
        <div className="flex w-full h-32 justify-center items-center align-middle">
          <Spin size="large" />
        </div>
      ) : (
        <Form
          className="bg-white "
          layout="vertical"
          initialValues={{
            phone: data?.phone,
            active: data?.active,
            role: data?.role,
            name: data?.name,
            cameras: data?.cameras,
          }}
          onFinish={onFinish}
        >
          <div className="p-4 flex flex-wrap">
            <div className="w-1/2 p-2">
              <Form.Item
                label="Зураг"
                name="photo_1_url"
                // rules={[{ required: true }]}
              >
                <FileUpload
                  preview={true}
                  disabled={false}
                  limit={1}
                  field="photo_1_url"
                  root="phone"
                />
              </Form.Item>
              <Form.Item
                label="Зураг"
                name="photo_1_url"
                // rules={[{ required: true }]}
              >
                <FileUpload
                  preview={true}
                  disabled={false}
                  limit={1}
                  field="photo_1_url"
                  root="phone"
                />
              </Form.Item>
            </div>
            <div className="w-1/2 p-2">
              <Form.Item label="Эцэг/эх/-ийн нэр" name="lastname">
                <Input
                  placeholder="Бичих"
                  disabled={user.role === "superior" ? false : true}
                />
              </Form.Item>

              <Form.Item label="Нэр" name="name">
                <Input
                  placeholder="Бичих"
                  disabled={user.role === "superior" ? false : true}
                />
              </Form.Item>
              <Form.Item label="Утас" name="phone">
                <Input
                  required
                  minLength={8}
                  maxLength={8}
                  placeholder="Бичих"
                  disabled={user.role === "superior" ? false : true}
                />
              </Form.Item>
              <Form.Item label="Хэрэгчийн төрөл" name="role">
                <Select>
                  <Option value="superior">Супер админ</Option>
                  <Option value="admin">Админ</Option>
                  <Option value="operator">Оператор</Option>
                </Select>
              </Form.Item>
              <Form.Item label="Хариуцах камер" name="cameras">
                <Select {...selectProps} />
              </Form.Item>
              <Form.Item label="Төлөв" name="active">
                <Select>
                  <Option value={true}>Идэвхтэй</Option>
                  <Option value={false}>Идэвхгүй</Option>
                </Select>
              </Form.Item>
            </div>
            {user.role === "superior" && (
              <div className="flex  w-full justify-end items-end px-2">
                <Form.Item>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="w-full"
                    danger
                  >
                    Хадгалах
                  </Button>
                </Form.Item>
              </div>
            )}
          </div>
        </Form>
      )}
    </div>
  );
};

export default Forms;
