import React, { useEffect, useState } from "react";
import { Select, Spin } from "antd";
import { mainApi } from "../utils/api";

const { Option } = Select;
const Index = ({
  url,
  data,
  method,
  disabled,
  value,
  onChange,
  list: propList,
  placeholder,
}) => {
  const [list, setlist] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    propList ? setlist(propList) : fetch();
  }, []);
  const fetch = async () => {
    let res = await mainApi({
      url: url,
      method: method,
      data: data ? data : {},
    });

    setloading(false);
    setlist(res.data);
  };
  return (
    <Select
      className="w-full"
      defaultActiveFirstOption
      placeholder={placeholder && placeholder}
      disabled={disabled}
      defaultValue={value && value}
      onChange={onChange}
      allowClear
    >
      {loading ? (
        <Spin />
      ) : (
        list.map(({ value, label }, index) => {
          return (
            <Option key={index} value={value ? value : label}>
              {label}
            </Option>
          );
        })
      )}
    </Select>
  );
};

export default Index;
