import Swal from "sweetalert2";

export const Alert = Swal.mixin({
  customClass: {
    confirmButton: "swal-btn secondary",
    cancelButton: "swal-btn primary",
  },
  buttonsStyling: false,
});

export const errorAlert = (error) => {
  return Alert.fire({
    html: error.message || error,
    icon: "warning",
  });
};

export const successAlert = (path, content) => {
  return Alert.fire({
    html: `${content ?? ""}`,
    icon: "success",
    timer: content ? undefined : 1000,
  });
};
