import React, { useState } from "react";
import Table from "../components/Table";
import { Button, Image } from "antd";
import { UserAddOutlined, FileAddOutlined } from "@ant-design/icons";
import { BsArrowRightCircle } from "react-icons/bs";
import { AiOutlineVideoCameraAdd } from "react-icons/ai";
import { Link } from "react-router-dom";
import { DATE_FORMAT } from "../const";
import moment from "moment";
const List = () => {
  // <Image width={50} height={50} src={photo_1_url} alt="" />
  const [total, settotal] = useState();
  const findColumns = [
    {
      placeholder: "Аймаг",
      index: "aimag",
      type: "text",
    },
    {
      placeholder: "Сум",
      index: "sum",
      type: "text",
    },
    {
      placeholder: "Камерны нэршил",
      index: "name",
      type: "text",
    },

    {
      placeholder: "Камерны код",
      index: "code",
      type: "text",
    },
    {
      placeholder: "Данс",
      index: "account",
      type: "text",
    },
  ];
  const columns = [
    {
      title: "Аймаг",
      dataIndex: "aimag",
    },
    {
      title: "Сум",
      dataIndex: "sum",
    },
    {
      title: "Камерны нэршил",
      dataIndex: "name",
    },

    {
      title: "Камерны код",
      dataIndex: "code",
    },
    {
      title: "Данс",
      dataIndex: "account",
    },
    {
      title: "Аймаг ZIP",
      dataIndex: "aimag_zip",
    },
    {
      title: "Сум ZIP",
      dataIndex: "sum_zip",
    },

    {
      title: "",
      dataIndex: "_id",

      render: (_id) => (
        <Link
          to={`edit/${_id}`}
          style={{
            minWidth: 30,
            minHeight: 30,
            justifyContent: "center",
            alignItems: "center",
            color: "#1890ff",
            alignSelf: "center",
          }}
        >
          <BsArrowRightCircle size={20} />
        </Link>
      ),
    },
  ];
  return (
    <div className="flex flex-col p-2">
      <div className="flex flex-row justify-between items-center bg-white p-3 border-l-8 border-def-primary mb-6">
        <div className="text-base font-semibold">Камерын жагсаалт</div>
        <div className="flex flex-row">
          <div className="p-2 font-semibold text-right ">Нийт : {total}</div>
          <Link
            to={`new/`}
            style={{
              minWidth: 30,
              minHeight: 30,
              justifyContent: "center",
              alignItems: "center",
              color: "#1890ff",
              alignSelf: "center",
            }}
          >
            <Button
              shape="circle"
              icon={<AiOutlineVideoCameraAdd size={20} />}
              size="middle"
            />
          </Link>
        </div>
      </div>

      <Table
        url={"/camera/table"}
        columns={columns}
        setTotal={settotal}
        findColumns={findColumns}
      />
    </div>
  );
};

export default List;
// 88880720
