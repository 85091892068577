import { Button, Form, Input, Tooltip, Modal } from "antd";
import {
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
  InfoCircleOutlined,
  MobileOutlined,
} from "@ant-design/icons";
import { errorAlert, successAlert } from "../utils/alert";
import { mainApi } from "../utils/api";
import sha256 from "sha256";
import { useAuth } from "../utils/auth.js";
import { useState } from "react";
const Auth = () => {
  const { login } = useAuth();
  const [loading, setloading] = useState(false);
  const [show, setShow] = useState(false);
  const [issent, setissent] = useState(false);
  const [error, seterror] = useState(null);
  const [phone, setphone] = useState(null);
  const [code, setcode] = useState(null);
  const [newpassword, setnewpassword] = useState(null);
  const [confirmLoading, setconfirmLoading] = useState(false);

  const onFinish = async ({ username, password }) => {
    try {
      setloading(true);
      let result = await mainApi({
        url: "/user/login",
        method: "POST",
        data: {
          phone: username,
          password: sha256(password.toString()).toString(),
        },
      });

      setloading(false);
      result && login(result.data);
    } catch (err) {
      errorAlert(err);
      setloading(false);
    }
  };
  const onFinishFailed = async (err) => {};
  const sentMessage = async () => {
    try {
      setconfirmLoading(true);
      let result = await mainApi({
        url: "/user/check",
        method: "POST",
        data: {
          phone: phone,
        },
      });

      if (result?.data?.user_id) {
        setissent(true);
        sendCode(result?.data?.user_id);
      } else result?.data?.message && seterror(result?.data?.message);
      setconfirmLoading(false);
    } catch (err) {
      errorAlert(err);
      setconfirmLoading(false);
    }
  };
  const sendCode = async (id) => {
    try {
      setconfirmLoading(true);
      await mainApi({
        url: "/otp/send",
        method: "POST",
        data: {
          object_id: id,
          action: "reset_user_password",
        },
      });
      setconfirmLoading(false);
    } catch (err) {
      errorAlert(err);
      setconfirmLoading(false);
    }
  };
  const changePwd = async () => {
    try {
      if (code != null || newpassword != null)
        errorAlert("Баталгаажуулах код эсвэл шинэ нууц үгээ оруулна уу.");
      setconfirmLoading(true);
      await mainApi({
        url: "/otp/verify",
        method: "PUT",
        data: {
          phone: phone,
          code: code,
          password: sha256(newpassword.toString()).toString(),
        },
      });
      successAlert("", "Нууц үг амжилттай солигдлоо.");
      setShow(false);
      setconfirmLoading(false);
    } catch (err) {
      errorAlert(err);
      setconfirmLoading(false);
    }
  };

  return (
    <div className="h-full flex flex-wrap bg-def-gray">
      <div className="w-full sm:flex hidden  md:w-2/3  h-screen bg-def-primary justify-center align-middle items-center">
        <img className="w-64 h-64" src="../img/atl.png" alt="" />
      </div>
      <div className="w-full h-screen md:w-1/3 flex flex-col shadow-lg">
        <div className="flex  justify-center align-middle object-center items-center h-screen">
          <div className="bg-white p-5 rounded-md shadow">
            <div className="flex flex-row align-middle items-center">
              <div className="w-2 bg-def-primary h-12 " />
              <div className="mt-8 mb-8 pl-3">
                <div className="font-bold text-xl">Нэвтрэх</div>
                <div className="text-xs">Камерын хяналтын систем</div>
              </div>
            </div>
            <Form
              name="basic"
              initialValues={{ username: "", password: "" }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="username"
                rules={[
                  { required: true, message: "Утасны дугаараа оруулна уу.!" },
                ]}
              >
                <Input
                  size="large"
                  maxLength={8}
                  prefix={
                    <MobileOutlined
                      color="#1890ff"
                      className="site-form-item-icon"
                    />
                  }
                  suffix={
                    <Tooltip
                      color="blue"
                      title="Бүртгүүлсэн утасны дугаараа оруулна."
                    >
                      <InfoCircleOutlined
                        style={{ color: "rgba(0,0,0,.45)" }}
                      />
                    </Tooltip>
                  }
                  placeholder="Утасны дугаар"
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[{ required: true, message: "Нууц үг оруулна уу.!" }]}
              >
                <Input.Password
                  size="large"
                  prefix={
                    <LockOutlined
                      color="#1890ff"
                      className="site-form-item-icon"
                    />
                  }
                  type="password"
                  placeholder="Нууц үг"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
              <div className="flex justify-end -mt-2 mb-2">
                <div
                  className="text-end text-def-primary hover:cursor-pointer font-semibold"
                  onClick={() => setShow(true)}
                >
                  Нууц үг мартсан?
                </div>
              </div>
              <Form.Item>
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  className="w-full"
                  primary
                >
                  Нэвтрэх
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
      <Modal
        title="Нууц үг солих"
        visible={show}
        onOk={() => (issent ? changePwd() : sentMessage())}
        confirmLoading={confirmLoading}
        onCancel={() => setShow(false)}
        okText={issent ? "хадгалах" : "мессеж илгээх"}
      >
        <Input
          placeholder="Утасны дугаар оруулах"
          allowClear
          value={phone}
          maxLength={8}
          onChange={(e) => {
            setphone(e.target.value);
          }}
          prefix={
            <MobileOutlined color="#1890ff" className="site-form-item-icon" />
          }
        />
        {issent && (
          <>
            <Input
              placeholder="Баталгаажуулах код оруулах"
              allowClear
              value={code}
              maxLength={4}
              className="mt-3"
              onChange={(e) => {
                setcode(e.target.value);
              }}
              prefix={
                <MobileOutlined
                  color="#1890ff"
                  className="site-form-item-icon"
                />
              }
            />
            <Input
              placeholder="Шинэ нууц үг"
              allowClear
              className="mt-3"
              value={newpassword}
              maxLength={8}
              onChange={(e) => {
                setnewpassword(e.target.value);
              }}
              prefix={
                <MobileOutlined
                  color="#1890ff"
                  className="site-form-item-icon"
                />
              }
            />
          </>
        )}
        {error && <div>{error}</div>}
      </Modal>
    </div>
  );
};

export default Auth;
