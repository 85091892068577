import React, { useEffect, useState, useRef } from "react";
import { mainApi } from "../utils/api";
import { FileExcelOutlined } from "@ant-design/icons";
// import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table, DatePicker } from "antd";
import Select from "../components/Select";
import moment from "moment";
import { VIOLATION } from "../const";
const DataTable = ({
  url,
  find: propsFind,
  sort: propsSort,
  limit: propsLimit = 1000,
  defaultFind,
  children,
  columns: propsColumns,
  paginationHide,
  totalHide,
  renderItem,
  renderHeader,
  renderFooter,
  singleSort,
  height,
  title,
  setTotal,
  findColumns,
}) => {
  const [loading, setLoading] = useState(false);

  const [items, setItems] = useState([]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(propsLimit);
  const [find, setFind] = useState({});
  const [sort, setSort] = useState({});
  const [selected, setSelected] = useState();

  const generateColumns = () => {
    let columns = [];
    propsColumns.map((row, index) => {
      columns.push({
        ...row,
        key: index,
        // ...createCol("name", filter),
      });
    });
    return columns;
  };

  useEffect(() => {
    console.log("🚀 ~ file: Table.js ~ line 51 ~ useEffect ~ useEffect");

    fetchData({ find, sort, offset, limit });
  }, [url, find, sort, offset, limit]);
  const fetchData = async ({ find, sort, offset, limit }) => {
    console.log("🚀 ~ file: Table.js ~ line 78 ~ fetchData ~ fetchData");
    setLoading(true);

    const data = {
      find: { ...find, ...propsFind, ...defaultFind },
      sort: { ...sort, ...propsSort },
      offset,
      limit: limit || propsLimit,
    };

    const response = await mainApi({
      url,
      method: "POST",
      data,
    });
    console.log("🚀 ~ file: Table.js ~ line 73 ~ fetchData ~ data", data);

    if (response) {
      setItems(response.data.items);
      // console.table(response.data.items);
      setTotal && setTotal(response.data.total);
    }

    setLoading(false);
  };

  useEffect(() => {
    setLimit(propsLimit);
  }, [propsLimit]);
  const filteChange = (field, value, type) => {
    if (type == "text") {
      let newFind = {
        ...find,
        [field]: { $regex: value, $options: "i" },
      };

      setFind(newFind);
    }
    if (type == "list") {
      if (value) {
        let newFind = {
          ...find,
          [field]: { $regex: value, $options: "i" },
        };
        setFind(newFind);
      } else {
        let newFind = { ...find };

        delete newFind[field];

        setFind(newFind);
      }
    }
    if (type == "date") {
      console.log("🚀 ~ file: Table.js ~ line 96 ~ filteChange ~ value", value);
      let newFind = {
        ...find,
        [field]: {
          $gte: moment(value).set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          }),
          $lte: moment(value)
            .add(1, "day")
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
        },
      };
      setFind(newFind);
    }
  };
  return (
    <div className="bg-white">
      {findColumns && (
        <div className="flex flex-col bg-white p-5 my-4 w-full">
          <div className="flex flex-row items-center ml-2">
            <SearchOutlined style={{ color: "#1890ff", fontSize: 20 }} />
            <div className="font-semibold ml-2 text-base">Хайлт</div>
          </div>
          <div className="flex flex-wrap">
            {findColumns?.map(({ placeholder, type, index }) => {
              if (type == "text")
                return (
                  <div className="md:w-1/5 w-full p-2">
                    <Input
                      onChange={(e) => {
                        filteChange(index, e.target.value, type);
                      }}
                      placeholder={placeholder}
                    />
                  </div>
                );

              if (type == "date")
                return (
                  <div className="md:w-1/5 w-full p-2">
                    <DatePicker
                      format={"YYYY-MM-DD"}
                      className="w-full"
                      mode="date"
                      placeholder={placeholder}
                      onChange={(date) => {
                        filteChange(
                          index,
                          moment(date).format("YYYY-MM-DD"),
                          type
                        );
                      }}
                    />
                  </div>
                );
              if (type == "list")
                return (
                  <div className="md:w-1/5 w-full p-2">
                    <Select
                      allowClear
                      placeholder={placeholder}
                      list={VIOLATION}
                      onChange={(e) => {
                        filteChange(index, e, type);
                      }}
                    />
                  </div>
                );
            })}
          </div>
        </div>
      )}

      <Table dataSource={items} columns={generateColumns()} loading={loading} />
    </div>
  );
};

export default DataTable;
